import * as React from 'react';
import {Grid, Paper} from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import {Layout} from "../common/Layout";
import {SEO} from "../components/seo";
import {Heading} from "../components/heading";

const privacyMarkdown = `
## Purpose of this privacy policy

This privacy policy aims to give you information on how CCGTrader.co.uk collects
and processes your personal data through your use of this website, including any
data you may provide through this website when you].

This website is not intended for children and we do not knowingly collect data
relating to children.

It is important that you read this privacy policy together with any other
privacy policy or fair processing policy we may provide on specific occasions
when we are collecting or processing personal data about you so that you are
fully aware of how and why we are using your data. This privacy policy
supplements other notices and privacy policies and is not intended to override
them.

## Controller

CCGTrader.co.uk is the controller and responsible for your personal data
(collectively referred to as CCGTrader.co.uk, "we", "us" or "our" in this
privacy policy).

## Contact details

If you have any questions about this privacy policy or our privacy practices,
please contact our data privacy manager in the following ways:

Full name of legal entity: CCGTrader.co.uk

Email address: [info@ccgtrader.co.uk](mailto:info@ccgtrader.co.uk?subject=Data%20privacy%20query)

You have the right to make a complaint at any time to the Information
Commissioner's Office (ICO), the UK supervisory authority for data protection
issues (www.ico.org.uk). We would, however, appreciate the chance to deal with
your concerns before you approach the ICO so please contact us in the first
instance.

## Changes to the privacy policy and your duty to inform us of changes

We keep our privacy policy under regular review.

It is important that the personal data we hold about you is accurate and
current. Please keep us informed if your personal data changes during your
relationship with us.

## Third-party links

This website may include links to third-party websites, plug-ins and
applications. Clicking on those links or enabling those connections may allow
third parties to collect or share data about you. We do not control these
third-party websites and are not responsible for their privacy statements. When
you leave our website, we encourage you to read the privacy policy of every
website you visit.

# The data we collect about you

Personal data, or personal information, means any information about an
individual from which that person can be identified. It does not include data
where the identity has been removed (anonymous data).

We may collect, use, store and transfer different kinds of personal data about
you which we have grouped together as follows:

-   **Identity Data** includes first name, maiden name, last name, username or
    similar identifier, marital status, title, date of birth and gender.

-   **Contact Data** includes billing address, delivery address, email address
    and telephone numbers.

-   **Financial Data** includes paypal account details.

-   **Transaction Data** includes details about payments to and from you and
    other details of products and services you have purchased from us.

-   **Technical Data** includes internet protocol (IP) address, your login data,
    browser type and version, time zone setting and location, browser plug-in
    types and versions, operating system and platform, and other technology on
    the devices you use to access this website.

-   **Profile Data** includes your username and password, purchases or orders
    made by you, your interests, preferences, feedback and survey responses.

-   **Usage Data** includes information about how you use our website, products
    and services.

-   **Marketing and Communications Data** includes your preferences in receiving
    marketing from us and our third parties and your communication preferences.

We also collect, use and share **Aggregated Data** such as statistical or
demographic data for any purpose. Aggregated Data could be derived from your
personal data but is not considered personal data in law as this data will
**not** directly or indirectly reveal your identity. For example, we may
aggregate your Usage Data to calculate the percentage of users accessing a
specific website feature. However, if we combine or connect Aggregated Data with
your personal data so that it can directly or indirectly identify you, we treat
the combined data as personal data which will be used in accordance with this
privacy policy.

We do not collect any **Special Categories of Personal Data** about you (this
includes details about your race or ethnicity, religious or philosophical
beliefs, sex life, sexual orientation, political opinions, trade union
membership, information about your health, and genetic and biometric data). Nor
do we collect any information about criminal convictions and offences.

## If you fail to provide personal data

Where we need to collect personal data by law, or under the terms of a contract
we have with you, and you fail to provide that data when requested, we may not
be able to perform the contract we have or are trying to enter into with you
(for example, to provide you with goods or services). In this case, we may have
to cancel a product or service you have with us but we will notify you if this
is the case at the time.

# How is your personal data collected?

We use different methods to collect data from and about you including through:

-   **Direct interactions.** You may give us your Identity, Contact and
    Financial Data by filling in forms or by corresponding with us by post,
    phone, email or otherwise. This includes personal data you provide when you:

-   apply for our products or services;

-   create an account on our website;

-   subscribe to our service or publications;

-   request marketing to be sent to you; give us feedback or contact us.

-   **Automated technologies or interactions.** As you interact with our
    website, we will automatically collect Technical Data about your equipment,
    browsing actions and patterns. We collect this personal data by using
    cookies, server logs and other similar technologies.

-   **Third parties or publicly available sources.** We will receive personal
    data about you from various third parties as set out below:

>   Technical Data from the following parties:

1.  analytics providers such as Google;

2.  advertising networks

3.  search information providers.

-   Contact, Financial and Transaction Data from providers of technical, payment
    and delivery services.

-   Identity and Contact Data from data brokers or aggregators.

-   Identity and Contact Data from publicly available sources, such as Companies
    House and the Electoral Register.

# How we use your personal data

We will only use your personal data when the law allows us to. Most commonly, we
will use your personal data in the following circumstances:

-   Where we need to perform the contract we are about to enter into or have
    entered into with you.

-   Where it is necessary for our legitimate interests (or those of a third
    party) and your interests and fundamental rights do not override those
    interests.

-   Where we need to comply with a legal obligation.

Generally, we do not rely on consent as a legal basis for processing your
personal data although we will get your consent before sending third party
direct marketing communications to you via email or text message. You have the
right to withdraw consent to marketing at any time by contacting us.

## Purposes for which we will use your personal data

We have set out below, in a table format, a description of all the ways we plan
to use your personal data, and which of the legal bases we rely on to do so. We
have also identified what our legitimate interests are where appropriate.

Note that we may process your personal data for more than one lawful ground
depending on the specific purpose for which we are using your data. Please
contact us if you need details about the specific legal ground we are relying on
to process your personal data where more than one ground has been set out in the
table below.

| **Purpose/Activity**                                                                                               | **Type of data**  | **Lawful basis for processing including basis of legitimate interest**                                                                                                                                                             |
| --------------------------------------------------------------------------------------------------------------------: | -------------------: | ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------: |
| To register you as a new customer                                                                                  | Identity, Contact                        | Performance of a contract with you |
| To process and deliver your order including: Manage payments, fees and charges. Collect and recover money owed to us.| Identity, Contact, Financial, Transaction, Marketing, Communication          | Performance of a contract with you. Necessary for our legitimate interests (to recover debts due to us) |
| To manage our relationship with you which will include: Notifying you about changes to our terms or privacy policy. Asking you to leave a review or take a survey | Identity, Contact, Profile, Marketing and Communications          | Performance of a contract with you. Necessary to comply with a legal obligation. Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services) |
| To enable you to partake in a prize draw, competition or complete a survey                                         | Identity, Contact, Profile, Usage, Marketing and Communications          | Performance of a contract with you. Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business) |
| To administer and protect our business and this website                                                            | Identity, Contact, Technical          | Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise) Necessary to comply with a legal obligation |
| To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the   | Identity, Contact, Profile, Usage, Marketing and Communications Technical          | Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our products/services, to develop them, to grow our business and to inform our marketing strategy) |
| To use data analytics to improve our website, products/services, marketing, customer relationships and experiences | Technical, Usage        | Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy) |
| To make suggestions and recommendations to you about goods or services that may be of interest to you              | Identity, Contact, Technical, Usage, Profile, Marketing and Communications          | Necessary for our legitimate interests (to develop our products/services and grow our business) |

## Marketing

We strive to provide you with choices regarding certain personal data uses,
particularly around marketing and advertising.

## Promotional offers from us

We may use your Identity, Contact, Technical, Usage and Profile Data to form a
view on what we think you may want or need, or what may be of interest to you.
This is how we decide which products, services and offers may be relevant for
you (we call this marketing).

You will receive marketing communications from us if you have requested
information from us or purchased goods or services from us and you have not
opted out of receiving that marketing.

## Third-party marketing

We will get your express opt-in consent before we share your personal data with
any third party for marketing purposes.

## Opting out

You can ask us or third parties to stop sending you marketing messages at any
time by following the opt-out links on any marketing message sent to you.

Where you opt out of receiving these marketing messages, this will not apply to
personal data provided to us as a result of a product/service purchase,
product/service experience or other transactions.

## Change of purpose

We will only use your personal data for the purposes for which we collected it,
unless we reasonably consider that we need to use it for another reason and that
reason is compatible with the original purpose. If you wish to get an
explanation as to how the processing for the new purpose is compatible with the
original purpose, please contact us.

If we need to use your personal data for an unrelated purpose, we will notify
you and we will explain the legal basis which allows us to do so.

Please note that we may process your personal data without your knowledge or
consent, in compliance with the above rules, where this is required or permitted
by law.

# Disclosures of your personal data

We may share your personal data with the parties set out below for the purposes
set out in the table *Purposes for which we will use your personal data*
above.

-   Internal Third Parties
-   External Third Parties
-   Specific third parties listed in the table *Purposes for which we will use your personal data* above.
-   Third parties to whom we may choose to sell, transfer or merge parts of our
    business or our assets. Alternatively, we may seek to acquire other
    businesses or merge with them. If a change happens to our business, then the
    new owners may use your personal data in the same way as set out in this
    privacy policy.

We require all third parties to respect the security of your personal data and
to treat it in accordance with the law. We do not allow our third-party service
providers to use your personal data for their own purposes and only permit them
to process your personal data for specified purposes and in accordance with our
instructions.

# International transfers

We do not transfer your personal data outside the European Economic Area
(**EEA**).

# Data security

We have put in place appropriate security measures to prevent your personal data
from being accidentally lost, used or accessed in an unauthorised way, altered
or disclosed. In addition, we limit access to your personal data to those
employees, agents, contractors and other third parties who have a business need
to know. They will only process your personal data on our instructions and they
are subject to a duty of confidentiality.

We have put in place procedures to deal with any suspected personal data breach
and will notify you and any applicable regulator of a breach where we are
legally required to do so.

# Data retention

**How long will you use my personal data for?**

We will only retain your personal data for as long as reasonably necessary to
fulfil the purposes we collected it for, including for the purposes of
satisfying any legal, regulatory, tax, accounting or reporting requirements. We
may retain your personal data for a longer period in the event of a complaint or
if we reasonably believe there is a prospect of litigation in respect to our
relationship with you.

To determine the appropriate retention period for personal data, we consider the
amount, nature and sensitivity of the personal data, the potential risk of harm
from unauthorised use or disclosure of your personal data, the purposes for
which we process your personal data and whether we can achieve those purposes
through other means, and the applicable legal, regulatory, tax, accounting or
other requirements.

# Your legal rights

Under certain circumstances, you have rights under data protection laws in
relation to your personal data.

## No fee usually required

You will not have to pay a fee to access your personal data (or to exercise any
of the other rights).

However, we may charge a reasonable fee if your request is clearly unfounded,
repetitive or excessive. Alternatively, we could refuse to comply with your
request in these circumstances.

## What we may need from you

We may need to request specific information from you to help us confirm your
identity and ensure your right to access your personal data (or to exercise any
of your other rights). This is a security measure to ensure that personal data
is not disclosed to any person who has no right to receive it. We may also
contact you to ask you for further information in relation to your request to
speed up our response.

## Time limit to respond

We try to respond to all legitimate requests within one month. Occasionally it
could take us longer than a month if your request is particularly complex or you
have made a number of requests. In this case, we will notify you and keep you
updated.

# Glossary

## LAWFUL BASIS

**Legitimate Interest** means the interest of our business in conducting and
managing our business to enable us to give you the best service/product and the
best and most secure experience. We make sure we consider and balance any
potential impact on you (both positive and negative) and your rights before we
process your personal data for our legitimate interests. We do not use your
personal data for activities where our interests are overridden by the impact on
you (unless we have your consent or are otherwise required or permitted to by
law). You can obtain further information about how we assess our legitimate
interests against any potential impact on you in respect of specific activities
by contacting us.

**Performance of Contract** means processing your data where it is necessary for
the performance of a contract to which you are a party or to take steps at your
request before entering into such a contract.

**Comply with a legal obligation** means processing your personal data where it
is necessary for compliance with a legal obligation that we are subject to.

**THIRD PARTIES**

## External Third Parties

-   Professional advisers acting as processors or joint controllers, including
    lawyers, bankers, auditors and insurers who provide consultancy, banking,
    legal, insurance and accounting services.

-   HM Revenue & Customs, regulators and other authorities acting as processors
    or joint controllers based in the United Kingdom who require reporting of
    processing activities in certain circumstances.

You have the right to:

**Request access** to your personal data (commonly known as a "data subject
access request"). This enables you to receive a copy of the personal data we
hold about you and to check that we are lawfully processing it.

**Request correction** of the personal data that we hold about you. This enables
you to have any incomplete or inaccurate data we hold about you corrected,
though we may need to verify the accuracy of the new data you provide to us.

**Request erasure** of your personal data. This enables you to ask us to delete
or remove personal data where there is no good reason for us continuing to
process it. You also have the right to ask us to delete or remove your personal
data where you have successfully exercised your right to object to processing
(see below), where we may have processed your information unlawfully or where we
are required to erase your personal data to comply with local law. Note,
however, that we may not always be able to comply with your request of erasure
for specific legal reasons which will be notified to you, if applicable, at the
time of your request.

**Object to processing** of your personal data where we are relying on a
legitimate interest (or those of a third party) and there is something about
your particular situation which makes you want to object to processing on this
ground as you feel it impacts on your fundamental rights and freedoms. You also
have the right to object where we are processing your personal data for direct
marketing purposes. In some cases, we may demonstrate that we have compelling
legitimate grounds to process your information which override your rights and
freedoms.

**Request restriction of processing** of your personal data. This enables you to
ask us to suspend the processing of your personal data in the following
scenarios:

-   If you want us to establish the data's accuracy.

-   Where our use of the data is unlawful but you do not want us to erase it.

-   Where you need us to hold the data even if we no longer require it as you
    need it to establish, exercise or defend legal claims.

-   You have objected to our use of your data but we need to verify whether we
    have overriding legitimate grounds to use it.

**Request the transfer** of your personal data to you or to a third party. We
will provide to you, or a third party you have chosen, your personal data in a
structured, commonly used, machine-readable format. Note that this right only
applies to automated information which you initially provided consent for us to
use or where we used the information to perform a contract with you.

**Withdraw consent at any time** where we are relying on consent to process your
personal data. However, this will not affect the lawfulness of any processing
carried out before you withdraw your consent. If you withdraw your consent, we
may not be able to provide certain products or services to you. We will advise
you if this is the case at the time you withdraw your consent.
`;

function Privacy() {
    return (
        <Layout>
            <SEO title="Privacy Policy" />

            <section>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Heading text="Privacy Policy" />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className="markdown p-4">
                            <ReactMarkdown children={privacyMarkdown} remarkPlugins={[remarkGfm]} />
                        </Paper>
                    </Grid>
                </Grid>
            </section>
        </Layout>
    );
}

export default Privacy;
